<template>
  <div>
    <!-- //注册账号 -->
    <div class="loginFromBox">
      <p style="text-align: left; color: rgba(0, 0, 0, 0.85)">
        <a>注册账号</a>
      </p>
      <a-form
        id="components-form-demo-normal-login"
        :form="form"
        class="login-form"
        @submit="handleSubmits"
      >
        <a-form-item v-bind="formItemLayout">
          <a-input
            style="width: 368px; height: 40px"
            placeholder="邮箱账号"
            v-model="des"
            v-decorator="[
              'email',
              {
                rules: [
                  {
                    type: 'email',
                    message: '请输入正确的邮箱',
                  },
                  {
                    required: true,
                    message: '输入你的邮箱',
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item v-bind="formItemLayout" has-feedback>
          <a-input
            style="width: 368px; height: 40px"
            placeholder="密码"
            v-decorator="[
              'password',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入你的密码',
                  },
                  {
                    validator: password,
                  },
                ],
              },
            ]"
            type="password"
          />
        </a-form-item>
        <a-form-item v-bind="formItemLayout" has-feedback>
          <a-input
            style="width: 368px; height: 40px"
            placeholder="确认"
            v-decorator="[
              'confirm',
              {
                rules: [
                  {
                    required: true,
                    message: '请确认你的密码',
                  },
                  {
                    validator: compareToFirstPassword,
                  },
                ],
              },
            ]"
            type="password"
            @blur="handleConfirmBlur"
          />
        </a-form-item>

        <a-form-item v-bind="formItemLayout">
          <a-input
            v-decorator="['invitation_code']"
            style="width: 368px; height: 40px"
            placeholder="邀请码"
          ></a-input>
        </a-form-item>
        <a-form-item
          v-bind="formItemLayout"
          style="max-height: 40px; overflow: hidden"
        >
          <a-input
            placeholder="验证码"
            style="width: 200px; height: 40px; float: left"
            v-decorator="[
              'code',
              {
                rules: [
                  {
                    required: true,
                    message: '输入你的验证码',
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item
          v-bind="formItemLayout"
          style="max-height: 40px; overflow: hidden"
        >
          <a-button
            type="primary"
            @click="getCode()"
            class="buttonOption"
            :disabled="getcodes"
            >{{
              comeman == 60 || comeman == 0 ? "发送验证码" : comeman
            }}</a-button
          >
        </a-form-item>

        <p
          style="
            text-align: left;
            color: rgba(0, 0, 0, 0.85);
            font-size: 14px;
            color: #f5222d;
          "
        >
          部分邮箱会将本验证邮件归入垃圾邮件，如您长时间未收到，请前往邮箱的垃圾邮件中查找。
        </p>

        <!-- 修改展示的熟悉值 -->
        <div class="yueduBOX">
          <div>
            <a-checkbox @change="yuede"></a-checkbox>
            我已阅读并同意
            <a
              :href="this.CONSTNAME.gotoRegulations"
              style="line-through; z-index:999"
              >服务条款</a
            >
          </div>
          <div><a @click="gologin" class="a_hre">使用已有账号登录</a></div>
        </div>
        <a-form-item v-bind="tailFormItemLayout">
          <a-button
            type="primary"
            html-type="submit"
            style="width: 140px; height: 40px"
            :disabled="this.zcState ? false : true"
            >注册</a-button
          >
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
import { message } from "ant-design-vue";
import api from "../../api/api";
export default {
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "normal_login" });
    this.form = this.$form.createForm(this, { name: "register" });
  },
  data() {
    return {
      zcState: false,
      des: "",
      invitation_code: "",
      comeman: 60,
      getcodes: false,
      confirmDirty: false,
      autoCompleteResult: [],
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
      },
      tailFormItemLayout: {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 16,
            offset: 8,
          },
        },
      },
    };
  },
  mounted() {
    this.invitation_code = this.$route.query.code;
    this.setCode();
  },

  methods: {
    //
    setCode() {
      this.form.setFieldsValue({
        invitation_code: this.invitation_code || "",
      });
    },
    gologin() {
      this.$router.push("/user");
    },
    //去首页
    gotolog() {
      this.$router.push("/user");
    },
    yuede() {
      this.zcState = !this.zcState;
    },
    //
    async getCode() {
      this.getcodes = true;
      this.form.validateFieldsAndScroll(async (err, values) => {
        const data = await api.getRegisterCode({ email: values.email });
        if (data.msg) {
          this.getcodes = false;
          if (data.status === 400) {
            message.error("邮箱已被注册");
          }
        } else {
          let keyNuber = 60;
          let tonumber = setInterval(() => {
            keyNuber--;
            this.comeman = keyNuber;
            if (keyNuber === 0) {
              this.getcodes = false;
              clearInterval(tonumber);
            }
          }, 1000);
        }
      });
    },
    //注册
    handleSubmits(e) {
      e.preventDefault();

      this.form.validateFieldsAndScroll(async (err, values) => {
        // let reg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,5}$/
        const { code, email, password } = values;
        if (code && email && password) {
          // if (reg.test()) return message.error('邮箱不正确')
          // if (password.length < 6) return message.error('密码不能小于6位数')
          const data = await api.logRegister({
            code,
            email,
            password,
            invitation_code: this.invitation_code,
          });

          var datastr = JSON.stringify(data, null, 2);
          console.log(`register response [${datastr}]`);

          if (data.msg != "ok") {
            if (data.msg === "email") {
              message.error("邮箱已被注册");
            } else if (data.msg === "password_length") {
              message.error("密码必须大于6位数");
            } else if (data.msg === "code") {
              message.error("验证码错误");
            } else {
              message.error("注册失败, 请重试");
            }
          } else {
            message.success("注册成功");
            setTimeout(() => {
              this.$router.push("/user");
            }, 1000);
          }
        } else {
          message.error("请填入必填项");
        }
      });
      //成功跳转 登录页面
    },
    //密码验证
    compareToFirstPassword(rule, value, callback) {
      const form = this.form;
      if (value && value !== form.getFieldValue("password")) {
        callback("两次输入的密码不一致");
      } else {
        callback();
      }
    },
    password(rule, value, callback) {
      if (value.length < 6) {
        callback("密码不少于6位");
      } else {
        callback();
      }
    },
    handleConfirmBlur(e) {
      const value = e.target.value;
      this.confirmDirty = this.confirmDirty || !!value;
    },
  },
};
</script>

<style scoped lang="scss">
.indexBox {
  width: 1200px;
  height: 500px;
  margin: auto;
  // border: 1px solid rgba(121, 121, 121, 1);
  box-sizing: border-box;
  //登录样式
  .loginBox {
    width: 100%;
    height: 100%;
    background: red($color: #000000);
    h1 {
      margin: auto;
      margin-top: 100px;
      text-align: center;
      font-weight: bold;
    }
    .loginFromBox {
      a {
        color: #1890ff;
        font-weight: 500;
        padding: 12px 16px;
        display: inline-block;
        border-bottom: #1890ff 2px solid;
      }
      .boxleft {
        width: 100%;
        height: 50px;
        text-align: right;
        a {
          display: inline;
          padding: 0;
          border-bottom: 1px solid #37352f;
          font-size: 14px;
          font-variant: tabular-nums;
          border: none;
          color: #37352f;
        }
      }
      margin: auto;
      margin-top: 30px;
      width: 368px;
      height: 350px;
      box-sizing: border-box;
      padding: 50px 0;
      .yueduBOX {
        width: 100%;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: nowrap;

        div {
          text-align: center;
          flex-grow: 1;
          display: inline-block;
          a {
            padding: 0;
            color: rgba(0, 0, 0, 0.65) !important;
            font-size: 14px;
            border: none;
          }
        }
      }
      .centerBox {
        display: flex;
        margin-bottom: 10px;
        justify-content: center;
      }
      .a_hre {
        text-align: center;
      }
      p {
        text-align: center;
        font-size: 18px;
        color: black;
      }
      .butonOption {
        width: 120px;
        height: 40px;
      }
    }
    .changeTab {
      width: 100%;
      height: 35px;

      display: flex;
      justify-content: space-between;
      span {
        font-size: 18px;
        font-weight: bold;
        display: inline-block;
      }
    }
  }
  //外部样式
  #components-form-demo-normal-login .login-form {
    max-width: 300px;
  }
  #components-form-demo-normal-login .login-form-forgot {
    float: right;
  }
  #components-form-demo-normal-login .login-form-button {
    margin: auto;
    width: 140px;
    height: 45px;
  }
  .a_hre {
    text-align: center;
  }
}
</style>
