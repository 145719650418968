var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"loginFromBox"},[_vm._m(0),_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":_vm.handleSubmits}},[_c('a-form-item',_vm._b({},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'email',
            {
              rules: [
                {
                  type: 'email',
                  message: '请输入正确的邮箱',
                },
                {
                  required: true,
                  message: '输入你的邮箱',
                } ],
            } ]),expression:"[\n            'email',\n            {\n              rules: [\n                {\n                  type: 'email',\n                  message: '请输入正确的邮箱',\n                },\n                {\n                  required: true,\n                  message: '输入你的邮箱',\n                },\n              ],\n            },\n          ]"}],staticStyle:{"width":"368px","height":"40px"},attrs:{"placeholder":"邮箱账号"},model:{value:(_vm.des),callback:function ($$v) {_vm.des=$$v},expression:"des"}})],1),_c('a-form-item',_vm._b({attrs:{"has-feedback":""}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password',
            {
              rules: [
                {
                  required: true,
                  message: '请输入你的密码',
                },
                {
                  validator: _vm.password,
                } ],
            } ]),expression:"[\n            'password',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '请输入你的密码',\n                },\n                {\n                  validator: password,\n                },\n              ],\n            },\n          ]"}],staticStyle:{"width":"368px","height":"40px"},attrs:{"placeholder":"密码","type":"password"}})],1),_c('a-form-item',_vm._b({attrs:{"has-feedback":""}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'confirm',
            {
              rules: [
                {
                  required: true,
                  message: '请确认你的密码',
                },
                {
                  validator: _vm.compareToFirstPassword,
                } ],
            } ]),expression:"[\n            'confirm',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '请确认你的密码',\n                },\n                {\n                  validator: compareToFirstPassword,\n                },\n              ],\n            },\n          ]"}],staticStyle:{"width":"368px","height":"40px"},attrs:{"placeholder":"确认","type":"password"},on:{"blur":_vm.handleConfirmBlur}})],1),_c('a-form-item',_vm._b({},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['invitation_code']),expression:"['invitation_code']"}],staticStyle:{"width":"368px","height":"40px"},attrs:{"placeholder":"邀请码"}})],1),_c('a-form-item',_vm._b({staticStyle:{"max-height":"40px","overflow":"hidden"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'code',
            {
              rules: [
                {
                  required: true,
                  message: '输入你的验证码',
                } ],
            } ]),expression:"[\n            'code',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '输入你的验证码',\n                },\n              ],\n            },\n          ]"}],staticStyle:{"width":"200px","height":"40px","float":"left"},attrs:{"placeholder":"验证码"}})],1),_c('a-form-item',_vm._b({staticStyle:{"max-height":"40px","overflow":"hidden"}},'a-form-item',_vm.formItemLayout,false),[_c('a-button',{staticClass:"buttonOption",attrs:{"type":"primary","disabled":_vm.getcodes},on:{"click":function($event){return _vm.getCode()}}},[_vm._v(_vm._s(_vm.comeman == 60 || _vm.comeman == 0 ? "发送验证码" : _vm.comeman))])],1),_c('p',{staticStyle:{"text-align":"left","color":"#f5222d","font-size":"14px"}},[_vm._v(" 部分邮箱会将本验证邮件归入垃圾邮件，如您长时间未收到，请前往邮箱的垃圾邮件中查找。 ")]),_c('div',{staticClass:"yueduBOX"},[_c('div',[_c('a-checkbox',{on:{"change":_vm.yuede}}),_vm._v(" 我已阅读并同意 "),_c('a',{staticStyle:{"z-index":"999"},attrs:{"href":this.CONSTNAME.gotoRegulations}},[_vm._v("服务条款")])],1),_c('div',[_c('a',{staticClass:"a_hre",on:{"click":_vm.gologin}},[_vm._v("使用已有账号登录")])])]),_c('a-form-item',_vm._b({},'a-form-item',_vm.tailFormItemLayout,false),[_c('a-button',{staticStyle:{"width":"140px","height":"40px"},attrs:{"type":"primary","html-type":"submit","disabled":this.zcState ? false : true}},[_vm._v("注册")])],1)],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticStyle:{"text-align":"left","color":"rgba(0, 0, 0, 0.85)"}},[_c('a',[_vm._v("注册账号")])])}]

export { render, staticRenderFns }